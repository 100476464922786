<template>
  <div>
    <div
      v-if="!score"
      class="chip"
      :style="`border: 1px solid ${findColorByTypeScore(
        level,
      )}; background-color: ${hex2rgba(findColorByTypeScore(level), 0.25)}`"
    >
      {{ riskTitle }}
    </div>
    <div v-else>
      <div
        v-if="multiple"
        class="chip-multiple-wrapper"
      >
        <div
          v-for="(item, i) in multipleScoreList"
          :key="i"
          class="chip chip-multiple"
          :class="{ 'chip-small': small }"
          :style="`background-color: ${findColorByTypeScore(item.score)};`"
        >
          {{ item.score }}
        </div>
      </div>
      <div
        v-else
        class="chip"
        :class="{ 'chip-small': small }"
        :style="`background-color: ${findColorByTypeScore(level)};`"
      >
        {{ level }}
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import appConfig from '@/utils/appConfig'

export default {
  props: {
    level: {
      type: [Number, String],
      default: 0,
    },
    score: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      riskTitle: 'Low',
    }
  },
  computed: {
    multipleScoreList() {
      return [{ score: this.level }, { score: 0 }, { score: 60 }]
    },
  },
  mounted() {
    this.calcScoreData()
  },
  methods: {
    findColorByTypeScore,
    hex2rgba,
    calcScoreData() {
      if (
        this.level >= 0 &&
        this.level <= appConfig.VUE_APP_RISK_LOW_THRESHOLD
      ) {
        this.riskTitle = 'Low'
      } else if (
        this.level > appConfig.VUE_APP_RISK_LOW_THRESHOLD &&
        this.level <= appConfig.VUE_APP_RISK_MEDIUM_THRESHOLD
      ) {
        this.riskTitle = 'Medium'
      } else {
        this.riskTitle = 'Severe'
      }
    },
  },
}
</script>

<style>
.chip {
  display: inline-block;
  border-radius: 40px;
  padding: 0 16px;
  font-size: 14px;
}

.chip-multiple-wrapper {
  display: flex;
  flex-direction: column-reverse;
  transform: translate(0, 6px);
}

.chip-multiple {
  margin-top: -23px;
}

.chip-small {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--space-cadet);
  font-size: 10px;
  line-height: 17px;
  font-weight: 500;
  width: max-content;
  padding: initial;
  min-width: 34px;
  border: 1px solid var(--white);
  border-radius: 14px;
}

@media (max-width: 767px) {
  .chip {
    font-size: 12px;
  }
}
</style>
