<template>
  <div
    class="gl-select-button-wrapper"
    :class="[{ 'gl-select-button__gray': grey }]"
  >
    <template
      v-for="(item, i) in options"
    >
      <div
        v-if="!item.hide"
        :key="i"
        class="gl-select-button__item"
        :class="[
          { 'gl-select-button__item--active': item.value === value.value },
          { 'gl-select-button__disabled': disabled },
          { 'gl-select-button__item--disabled': item.disabled },
        ]"
        @click="itemClick(item)"
      >
        {{ item.name }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemClick(item) {
      if (item.disabled || this.disabled) return
      this.$emit('input', item)
    },
  },
}
</script>

<style>
.gl-select-button-wrapper {
  display: flex;
  width: fit-content;
  background-color: var(--pale-blue-transparent);
  border-radius: 8px;
  height: 40px;
  flex-shrink: 0;
  padding: 4px;
}

.gl-select-button__item {
  display: inline-flex;
  padding: 8px 29px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: var(--accent-reflex-blue, #5a78ea);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}

.gl-select-button__item--active {
  color: var(--white);
  background-color: var(--accent-reflex-blue, #5a78ea);
}

.gl-select-button__disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.gl-select-button__item--disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.gl-select-button__gray {
  padding: 2px;
  height: 32px;
  background-color: var(--cotton-grey-f-5);
  border-radius: 6px;
}
.gl-select-button__gray .gl-select-button__item {
  color: var(--space-cadet);
  text-align: center;
  padding: 4px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.5;
}
.gl-select-button__gray .gl-select-button__item--active {
  border-radius: 4px;
  height: 28px;
  color: var(--space-cadet);
  background-color: var(--white);
  opacity: unset;
  box-shadow: 0px 2px 2px 0px rgba(14, 11, 61, 0.05);
}

@media (max-width: 767px) {
  .gl-select-button-wrapper {
    width: 100%;
  }

  .gl-select-button__item {
    width: inherit;
    font-size: 14px;
  }
}
</style>
