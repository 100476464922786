<template>
  <div class="report-bottom-info-actions">
    <GlButton
      class="report-bottom-info-actions__button"
      :icon-only="isMobile"
      pale-light
      title="Refresh"
      @click="refresh"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="20"
          link
          name="refresh2"
          width="20"
        />
      </template>
    </GlButton>

    <GlPopup
      v-model="isDownloadOpenComputed"
      class="report-download-button__wrapper"
    >
      <template #action>
        <GlButton
          class="report-bottom-info-actions__button"
          dark
          :disabled="!activeDownloadItems.length"
          :loading="downloadLoading"
          title="Download"
          @click="isDownloadOpenComputed = !isDownloadOpenComputed"
        >
          <template #beforeTitle>
            <gl-icon
              class="mr-2"
              height="20"
              :hover="false"
              name="download2"
              width="20"
            />
          </template>
        </GlButton>
      </template>
      <template #content>
        <div
          v-for="item in activeDownloadItems"
          :key="item.value"
        >
          <div
            v-if="!item.disabled"
            class="report-download-button__dropdown-item"
            @click="onClickDownloadItem(item)"
          >
            <gl-icon
              height="16"
              :hover="false"
              light
              :name="item.icon"
              width="16"
            />
            <div> {{ item.label }} </div>
          </div>
        </div>
      </template>
    </GlPopup>

    <GlButton
      class="report-bottom-info-actions__button"
      dark
      :icon-only="isMobile"
      title="Explore Graph"
      @click="onExploreClick"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="14"
          :hover="false"
          light
          name="vision2"
          width="16"
        />
      </template>
    </GlButton>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlButton from '@/components/gl-button'
import GlPopup from '@/components/gl-popup'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'TopTxActions',
  components: {
    GlIcon,
    GlButton,
    GlPopup,
  },
  mixins: [deviceWidthMixin],
  props: {
    reportType: {
      type: String,
      default: 'address',
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    downloadLoading: {
      type: Boolean,
      default: false,
    },
    enableDownloadItems: {
      type: Array,
      Object,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDownloadOpen: false,
      downloadItems: [
        { label: 'Download PDF', icon: 'pdf', value: 'pdf', disabled: false },
        { label: 'Download CSV', icon: 'csv', value: 'csv', disabled: false },
      ],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    title() {
      return 'Transaction Risk Report'
    },
    activeDownloadItems() {
      return this.downloadItems.filter(item =>
        this.enableDownloadItems.includes(item.value),
      )
    },
    isDownloadOpenComputed: {
      get: function () {
        return this.isDownloadOpen && !!this.activeDownloadItems.length
      },
      set: function (e) {
        this.isDownloadOpen = e
      },
    },
  },
  methods: {
    onExploreClick() {
      if (this.reportType == 'address') {
        this.explore(this.info.address, this.reportType)
      }
      if (this.reportType == 'tx') {
        this.explore(this.info.tx_hash, this.reportType)
      }
    },
    explore(target, reportType) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { [reportType]: target, type: this.coinData.key },
      })
      window.open(href, '_blank')
    },
    refresh() {
      window.location.reload()
    },
    onClickDownloadItem({ value }) {
      this.isDownloadOpen = false
      if (value === 'pdf') {
        this.$emit('export-to-pdf')
      }
      if (value === 'csv') {
        this.$emit('export-report-to-csv')
      }
    },
  },
}
</script>
<style>
.report-bottom-info-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.report-bottom-info-actions__button {
  height: 48px;
  min-width: 48px;
  width: 243px;
  text-transform: none;
  font-weight: 600;
  line-height: 24px;
}

.report-download-button__wrapper {
  position: relative;
}
.report-download-button__wrapper .gl-popup-content {
  left: 0;
  right: 0;
  padding: 6px;
}
.report-download-button__dropdown-item {
  display: flex;
  align-items: center;
  padding: var(--Corner-radius-S);
  gap: var(--Corner-radius-S);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}
.report-download-button__dropdown-item:hover {
  border-radius: var(--Corner-radius-XS);
  background: var(--Accent-Coton-Grey);
}

@media (max-width: 1200px) {
  .report-bottom-info-actions__button {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .report-bottom-info-actions__button {
    width: 48px;
  }
  .report-bottom-info-actions {
    gap: 16px;
  }
  .report-bottom-info-actions .report-download-button__wrapper {
    flex: 1;
    order: 3;
  }
  .report-bottom-info-actions
    .report-download-button__wrapper
    .report-bottom-info-actions__button {
    width: 100%;
  }
}
</style>
