<template>
  <div class="bars-control">
    <div class="bars-control__left">
      <GlSelectButton
        :disabled="disabled"
        grey
        :options="getPartBarOptions"
        :value="getActivePartBar"
        @input="typeChange"
      />
    </div>
    <div class="bars-control__center">
      <GlSelectButton
        v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
        :disabled="disabled"
        grey
        :options="getExposurePartOptions"
        :value="getActiveExposurePart"
        @input="fundsChange"
      />
    </div>
    <div class="bars-control__right">
      <DropDownSort
        :active-sort="activeSort"
        :options-for-sort="optionsForSort"
        @sort-change="sortChange"
      />
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapMutations } from 'vuex'
// components
import GlSelectButton from '@/components/gl-select-button'
import DropDownSort from '@/pages/report/components/bars/DropDownSort'
// Utils
import appConfig from '@/utils/appConfig'

export default {
  components: {
    GlSelectButton,
    DropDownSort,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    optionsForSort: {
      type: Array,
      default: () => [],
    },
    activeSort: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('directions', [
      'getActiveExposurePart',
      'getExposurePartOptions',
      'getActivePartBar',
      'getPartBarOptions',
    ]),
    appConfig() {
      return appConfig
    },
  },
  methods: {
    ...mapMutations('directions', [
      'SET_ACTIVE_EXPOSURE_PART',
      'SET_ACTIVE_PART_BAR',
    ]),
    typeChange(val) {
      this.SET_ACTIVE_PART_BAR(val)
    },
    fundsChange(val) {
      this.SET_ACTIVE_EXPOSURE_PART(val)
    },
    sortChange(value) {
      this.$emit('sort-change', value)
    },
  },
}
</script>

<style>
.bars-control {
  display: flex;
  gap: 16px;
}
.bars-control__left,
.bars-control__right {
  flex: 1;
}
.bars-control__right {
  display: flex;
  justify-content: flex-end;
}
.bars-control__right .dd-sort {
  width: 175px;
}
.bars-control__center {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}

.bars-control__left .gl-select-button-wrapper .gl-select-button__item {
  width: 108px;
}
.bars-control__center .gl-select-button-wrapper .gl-select-button__item {
  width: 150px;
}

@media (max-width: 930px) {
  .bars-control {
    flex-wrap: wrap;
  }
  .bars-control__right {
    order: 1;
  }
  .bars-control__center {
    order: 2;
  }
}

@media (max-width: 767px) {
  .bars-control {
    flex-direction: column;
  }

  .bars-control__left .gl-select-button-wrapper .gl-select-button__item {
    width: unset;
    flex: 1;
  }
  .bars-control__center .gl-select-button-wrapper .gl-select-button__item {
    width: unset;
    flex: 1;
  }
  .bars-control__right .dd-sort {
    width: 100%;
  }
}
</style>
