<template>
  <div>
    <div class="flex gl-requests-counter__title">
      <div v-if="!userData.requestsTotal">
        Unlimited
      </div>
      <div
        v-else
        class="flex"
      >
        <span
          class="mr-1"
          :class="[{ 'warning-text': isWithinPercentage(Math.max(userData.requestsLeft, 0), userData.requestsTotal) }]"
        >
          {{ toComaSeparate(Math.max(userData.requestsLeft, 0)) }}
        </span> /
        <span class="ml-1">{{ toComaSeparate(userData.requestsTotal) }}</span>
      </div>
    </div>
    <div class="gl-requests-counter__sub-title">
      Requests remain
    </div>
  </div>
</template>


<script>
// Vuex
import { mapState } from 'vuex'
// Utils
import { isWithinPercentage } from '@/utils/profile'
import { toComaSeparate } from '@/utils/formatNumber'

export default {
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    toComaSeparate,
    isWithinPercentage
  }
}

</script>

<style>
.gl-requests-counter__title {
  margin-bottom: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--space-cadet);
}

.gl-requests-counter__sub-title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  color: var(--dark-grey-6-e);
  white-space: nowrap;
}
</style>
