import { formatDate } from '@/utils/format-date'
import { findMinMaxFields } from '@/utils/model'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { getUniqueTxLengthFromSource } from '@/utils/report-data-formatter'
import {
  formatFunds,
  getUniqAndSortedTags,
  getOwnerTypesAndTags,
  accumulateValues,
} from '@/utils/report-data-formatter'
import { formatByPrice } from '@/utils/format-by-price'
import { getNestedValueFromObj } from '@/utils/helpers'
import { calculateRiskLevel } from '@/utils/risk-formatter'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatterAmountValue } from '@/utils/report-data-formatter'
import {
  capitalizeFirstLetter,
  spamMessagesCombine,
} from '@/utils/text-formatter'

const headerForTransactions = [
  'TYPE/TAG',
  'RISK LABEL',
  'ENTITY',
  'AMOUNT',
  'PRICE',
  'EXPOSURE',
  'ROOT TX',
  'LEAF TX',
  'DEPTH',
  'DATE & TIME',
]
const headerForTransactionsSwaps = [
  'SWAP DEPOSIT AMOUNT',
  'SWAP DEPOSIT PRICE',
  'SWAP WITHDRAW AMOUNT',
  'SWAP WITHDRAW PRICE',
]

const csvData = {
  csvHeader: {
    type: 'key-value',
    title:
      'This report is generated using Global Ledger proprietary technology, research capabilities, and community reports.',
    data: {},
  },
  csvAddressInfo: {
    type: 'key-value',
    title: 'ADDRESS INFORMATION',
    data: {},
  },
  csvTxInfo: {
    type: 'key-value',
    title: 'TRANSACTION INFORMATION',
    data: {},
  },
  csvRiskScore: {
    type: 'key-value',
    data: {},
  },
  csvProfileInfo: {
    type: 'key-value',
    title: 'Profile',
    data: {},
  },
  csvSofEvaluated: {
    type: 'key-value',
    title: 'SOURCE OF FUNDS RISK EXPOSURE',
    data: {},
  },
  csvSofTypes: {
    type: 'table',
    title: 'SOURCE OF FUNDS RISK LABELS',
    header: ['TYPE', 'AMOUNT', 'PRICE', 'SHARE'],
    data: [],
  },
  csvSofOwners: {
    type: 'table',
    title: 'SOURCE OF FUNDS ENTITY',
    header: ['ENTITY', 'AMOUNT', 'PRICE', 'SHARE'],
    data: [],
  },
  csvSofTransactions: {
    type: 'table',
    title: 'SOURCE OF FUNDS TRANSACTIONS',
    header: [],
    data: [],
  },
  csvUofEvaluated: {
    type: 'key-value',
    title: 'USE OF FUNDS RISK EXPOSURE',
    data: {},
  },
  csvUofTypes: {
    type: 'table',
    title: 'USE OF FUNDS RISK LABELS',
    header: ['TYPE', 'AMOUNT', 'PRICE', 'SHARE'],
    data: [],
  },
  csvUofOwners: {
    type: 'table',
    title: 'USE OF FUNDS ENTITY',
    header: ['ENTITY', 'AMOUNT', 'PRICE', 'SHARE'],
    data: [],
  },
  csvUofTransactions: {
    type: 'table',
    title: 'USE OF FUNDS TRANSACTIONS',
    header: [],
    data: [],
  },
  csvScoreErrorMessage: {
    type: 'key-value',
    data: {},
  },
}

export const csvExportReport = ({
  reportType = '',
  coinData = {},
  addressInfo = {},
  txInfo = {},
  allDataSource = {},
  allDataSourceByOwner = {},
  currenciesByDirection = {},
  profileInfo = {},
  pureSources = {},
  selectedToken = {},
  totalFunds = '',
  currentScoreErrorMessage = {},
}) => {
  csvData.csvHeader.data = {}
  csvData.csvAddressInfo.data = {}
  csvData.csvTxInfo.data = {}
  csvData.csvProfileInfo.data = {}
  csvData.csvSofEvaluated.data = {}
  csvData.csvSofTypes.data = []
  csvData.csvSofOwners.data = []
  csvData.csvUofEvaluated.data = {}
  csvData.csvUofTypes.data = []
  csvData.csvUofOwners.data = []
  csvData.csvSofTransactions.data = []
  csvData.csvUofTransactions.data = []

  const hasAdditionalInfo = coinData.family === 'btc'
  const isAllSelected = selectedToken.address == 'all'

  let headerForTransactionsResult = headerForTransactions

  if (coinData.isNeedTokensCall) {
    headerForTransactionsResult = [
      ...headerForTransactions,
      ...headerForTransactionsSwaps,
    ]
  }

  if (isAllSelected) {
    headerForTransactionsResult.splice(3, 1)
  }

  csvData.csvSofTransactions.header = headerForTransactionsResult
  csvData.csvUofTransactions.header = headerForTransactionsResult

  // Address info
  if (reportType == 'address') {
    csvData.csvHeader.data['Address'] = addressInfo.address || '–'
    csvData.csvHeader.data['Blockchain'] =
      addressInfo.coinKey?.toUpperCase() || '–'
    if (coinData.isNeedTokensCall) {
      csvData.csvHeader.data['Token'] = selectedToken.symbol || '–'
    }
    csvData.csvHeader.data['Author'] = 'Global Ledger'
    csvData.csvHeader.data['Report Generated'] = addressInfo.createdAt
      ? formatDate(new Date(addressInfo.createdAt), 'dd.MM.yyyy HH:mm')
      : '–'

    csvData.csvAddressInfo.data[
      hasAdditionalInfo ? 'Balance' : 'Token Balance'
    ] = addressInfo.balance || '–'
    csvData.csvAddressInfo.data[
      hasAdditionalInfo ? 'Balance Price' : 'Token Balance Price'
    ] = formatByPrice(addressInfo.price) || '–'

    csvData.csvAddressInfo.data['Total Transactions'] =
      addressInfo.totalTransactions || '–'
    csvData.csvAddressInfo.data['Transactions Sent'] =
      addressInfo.transactionsSent || '–'
    csvData.csvAddressInfo.data['Transactions Received'] =
      addressInfo.transactionsReceived || '–'
    if (hasAdditionalInfo) {
      csvData.csvAddressInfo.data['Total Sent'] = addressInfo.totalSent || '–'
      csvData.csvAddressInfo.data['Total Sent Price'] =
        formatByPrice(addressInfo.totalSentPrice) || '–'

      csvData.csvAddressInfo.data['Total Received'] =
        addressInfo.totalReceived || '–'
      csvData.csvAddressInfo.data['Total Received Price'] =
        formatByPrice(addressInfo.totalReceivedPrice) || '–'

      csvData.csvAddressInfo.data['First Seen'] = addressInfo.firstSeen || '–'
      csvData.csvAddressInfo.data['Last Seen'] = addressInfo.lastSeen || '–'
    }
  }
  // Tx info
  if (reportType == 'tx') {
    csvData.csvHeader.data['Transaction Hash'] = txInfo.tx_hash || '–'
    csvData.csvHeader.data['Blockchain'] = txInfo.coinKey?.toUpperCase() || '–'
    if (coinData.isNeedTokensCall) {
      csvData.csvHeader.data['Token'] = selectedToken.symbol || '–'
    }
    csvData.csvHeader.data['Author'] = 'Global Ledger'
    csvData.csvHeader.data['Report Generated'] = txInfo.createdAt
      ? formatDate(txInfo.createdAt, 'dd.MM.yyyy HH:mm')
      : '–'

    csvData.csvTxInfo.data['Total Amount'] = txInfo.formattedTotalAmount || '–'
    csvData.csvTxInfo.data['Total Amount Price'] =
      formatByPrice(txInfo.price) || '–'

    csvData.csvTxInfo.data['Block'] = txInfo.blockHeight || '–'

    if (hasAdditionalInfo) {
      csvData.csvTxInfo.data['Total Inputs'] = txInfo.totalInputs || '–'
      csvData.csvTxInfo.data['Total Outputs'] = txInfo.totalOutputs || '–'
    }

    csvData.csvTxInfo.data['Timestamp'] = txInfo.timestamp
      ? formatDate(txInfo.timestamp * 1000, 'dd.MM.yyyy HH:mm')
      : '–'
  }

  // RISK SCORE
  const hasFundsData =
    pureSources?.incoming?.length || pureSources?.outgoing?.length
  const riskScore = hasFundsData ? formatFunds(totalFunds, false) : '–'

  csvData.csvRiskScore.data['RISK SCORE'] = riskScore
  const riskLevel = calculateRiskLevel(riskScore)
    ? capitalizeFirstLetter(calculateRiskLevel(riskScore))
    : '–'
  csvData.csvRiskScore.data['RISK RATING'] = riskLevel

  // Profile
  if (profileInfo?.clusterData?.owner) {
    csvData.csvProfileInfo.data['Entity'] = profileInfo.clusterData.owner
  }

  const clusterLabels = getOwnerLabels(profileInfo.clusterData)

  if (clusterLabels && clusterLabels?.length) {
    csvData.csvProfileInfo.data['Entity Labels'] = clusterLabels
      .map(v => `${v.name}`)
      .join(', \n')
  }

  if (profileInfo?.ownerData?.owner) {
    csvData.csvProfileInfo.data['Address Owner'] = profileInfo.ownerData.owner
  }

  const ownerLabels = getOwnerLabels(profileInfo.ownerData)

  if (ownerLabels && ownerLabels?.length) {
    csvData.csvProfileInfo.data['Address Labels'] = ownerLabels
      .map(v => `${v.name}`)
      .join(', \n')
  }

  let amountType = 'amount'

  if (isAllSelected) amountType = 'amountCur'

  // SOF bars
  if (pureSources.incoming?.length) {
    csvData.csvSofEvaluated.data['Evaluated Transactions'] =
      getUniqueTxLengthFromSource(pureSources.incoming)

    if (!isAllSelected) {
      csvData.csvSofEvaluated.data['Evaluated Amount'] = accumulateValues({
        dataSource: pureSources.incoming,
        key: 'amount',
        formatterValue: true,
        coinData,
        selectedToken,
      })
    }

    csvData.csvSofEvaluated.data['Evaluated Price'] =
      formatByPrice(
        accumulateValues({
          dataSource: pureSources.incoming,
          key: 'amountCur',
        }),
      ) || '–'
  }

  if (allDataSource.incoming?.length) {
    if(isAllSelected) {
      csvData.csvSofTypes.header = ['TYPE', 'PRICE', 'SHARE']
    }
    csvData.csvSofTypes.data = mapDataSourceByPath({
      source: allDataSource.incoming,
      amountType,
    })
  }

  if (allDataSourceByOwner.incoming?.length) {
    if(isAllSelected) {
      csvData.csvSofOwners.header = ['TYPE', 'PRICE', 'SHARE']
    }
    csvData.csvSofOwners.data = mapDataSourceByPath({
      source: allDataSourceByOwner.incoming,
      path: 'owner',
      amountType,
    })
  }

  // SOF table
  if (pureSources.incoming?.length) {
    csvData.csvSofTransactions.data = mapTransactions({
      sources: pureSources.incoming,
      coinData,
      currencies: currenciesByDirection.incoming,
      removeIndex: isAllSelected ? 3 : null,
    })
  }

  // UOF bars
  if (pureSources.outgoing?.length) {
    csvData.csvUofEvaluated.data['Evaluated Transactions'] =
      getUniqueTxLengthFromSource(pureSources.outgoing)

    if (!isAllSelected) {
      csvData.csvUofEvaluated.data['Evaluated Amount'] = accumulateValues({
        dataSource: pureSources.outgoing,
        key: 'amount',
        formatterValue: true,
        coinData,
        selectedToken,
      })
    }

    csvData.csvUofEvaluated.data['Evaluated Price'] =
      formatByPrice(
        accumulateValues({
          dataSource: pureSources.outgoing,
          key: 'amountCur',
        }),
      ) || '–'
  }

  if (allDataSource.outgoing?.length) {
    if(isAllSelected) {
      csvData.csvUofTypes.header = ['TYPE', 'PRICE', 'SHARE']
    }
    csvData.csvUofTypes.data = mapDataSourceByPath({
      source: allDataSource.outgoing,
      amountType,
    })
  }

  if (allDataSourceByOwner.outgoing?.length) {
    if(isAllSelected) {
      csvData.csvUofOwners.header = ['TYPE', 'PRICE', 'SHARE']
    }
    csvData.csvUofOwners.data = mapDataSourceByPath({
      source: allDataSourceByOwner.outgoing,
      path: 'owner',
      amountType,
    })
  }

  //  UOF table
  if (pureSources.outgoing?.length) {
    csvData.csvUofTransactions.data = mapTransactions({
      sources: pureSources.outgoing,
      coinData,
      currencies: currenciesByDirection.outgoing,
      removeIndex: isAllSelected ? 3 : null,
    })
  }

  let errorMessage = ''

  if (!pureSources.outgoing?.length && !pureSources.outgoing?.length) {
    errorMessage = `There are no transactions on this ${
      reportType == 'tx' ? 'tx' : 'address'
    }`
  }

  if (spamMessagesCombine({ meta: currentScoreErrorMessage.meta })) {
    errorMessage = spamMessagesCombine({ meta: currentScoreErrorMessage.meta })
  }
  if (spamMessagesCombine({ selfFunding: currentScoreErrorMessage.selfFunding })) {
    errorMessage = spamMessagesCombine({ selfFunding: currentScoreErrorMessage.selfFunding })
  }

  if (errorMessage) {
    csvData.csvScoreErrorMessage.data['Error message'] = errorMessage
  }

  let nameResult = 'GL-report'
  if (reportType === 'address') nameResult = addressInfo.address
  if (reportType === 'tx') nameResult = txInfo.tx_hash

  exportToCsv({ contentData: csvData, nameResult })
}

const exportToCsv = ({ contentData = {}, nameResult }) => {
  let csvContent = ''

  Object.keys(contentData).forEach((key, index, keys) => {
    const section = contentData[key]

    const hasData =
      section.type === 'key-value'
        ? Object.keys(section.data)?.length > 0
        : section.data?.length > 0

    if (hasData) {
      if (section.title) {
        csvContent += section.title + '\n'
      }

      if (section.type === 'key-value') {
        csvContent +=
          Object.entries(section.data)
            .map(([key, value]) => {
              if (value === 'divider') {
                return
              } else {
                return `${key.replaceAll('_', ' ')};"${value}"`
              }
            })
            .join('\n') + '\n'
      } else if (section.type === 'table') {
        if (section.header) {
          csvContent += section.header.join(';') + '\n'
        }
        csvContent +=
          section.data
            .map(row => row.map(item => `"${item || ''}"`).join(';'))
            .join('\n') + '\n'
      }

      if (index < keys?.length - 1) {
        csvContent += '\n\n'
      }
    }
  })

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `${nameResult}_${formatDate(new Date())}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

const getOwnerLabels = ownerData => {
  return getUniqAndSortedTags(getOwnerTypesAndTags(ownerData)) || []
}

const mapDataSourceByPath = ({
  source = [],
  path = 'funds.type',
  amountType = 'amount',
}) => {
  return (
    source &&
    source.map(obj => {
      const amount = obj?.formattedAmount

      const name = getNestedValueFromObj(obj, path) ?? ''

      const share = formatShare(roundShare(obj.share))

      const price = formatByPrice(obj.amountCur)

      return amountType == 'amount' ? [name, amount, price, share] : [name, price, share]
    })
  )
}

const mapTransactions = ({
  sources = [],
  coinData = {},
  currencies = [],
  removeIndex = null,
}) => {
  return sources.map(obj => {
    const baseArray = [
      obj.funds.type || '–',
      calculateRiskLevel(obj.funds.score),
      obj.owner,
      obj.formattedAmount || '',
      formatByPrice(obj.amountCur),
      formatShare(roundShare(obj.share)),
      obj.directTx,
      obj.tx_hash || 'Multiple',
      findMinMaxFields(obj.depth),
      obj.timestamp
        ? formatDate(
            new Date(
              (typeof obj.timestamp === 'object'
                ? obj.timestamp.minimum
                : obj.timestamp) * 1000,
            ),
            'dd.MM.yyyy HH:mm',
          )
        : '–',
    ]

    if (coinData.isNeedTokensCall && obj.listType === 'Swap') {
      baseArray.push(
        generateSwapAmount({ data: obj.inputAmounts, coinData, currencies }),
        generateSwapPrice({ data: obj.inputAmounts }),
        generateSwapAmount({ data: obj.outputAmounts, coinData, currencies }),
        generateSwapPrice({ data: obj.outputAmounts }),
      )
    }

    if (removeIndex) {
      baseArray.splice(removeIndex, 1)
    }

    return baseArray
  })
}

const generateSwapPrice = ({ data = [] }) => {
  if (!data) return
  let objWithCurrency = data.find(item => 'currency' in item)

  if (objWithCurrency) {
    return formatByPrice(objWithCurrency.amountCur)
  } else {
    return formatByPrice(data[0].amountCur)
  }
}

const generateSwapAmount = ({ data = [], coinData, currencies }) => {
  if (!data) return
  let objWithCurrency = data.find(item => 'currency' in item)

  if (objWithCurrency) {
    return toComaSeparate(
      formatterAmountValue(
        objWithCurrency?.amount,
        currencies[objWithCurrency.currency]?.decimals,
        currencies[objWithCurrency.currency]?.currency || 'Unknown Token',
      ),
    )
  } else {
    return toComaSeparate(
      formatterAmountValue(data[0].amount, coinData.decimals, coinData.label),
    )
  }
}

export const csvExport = (exportData, csvName = 'logs') => {
  let csvContent = 'data:text/csv;charset=utf-8,'
  csvContent += [
    Object.keys(exportData[0]).join(','),
    ...exportData.map(item =>
      Object.values(item)
        .map(
          item => `"${item ? item.replace('#', encodeURIComponent('#')) : ''}"`,
        )
        .join(','),
    ),
  ]
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '')

  const data = encodeURI(csvContent)
  const link = document.createElement('a')

  link.setAttribute('href', data)
  link.setAttribute('download', `${csvName}_${formatDate(new Date())}.csv`)
  link.click()
}
