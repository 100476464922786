<template>
  <a
    v-if="url"
    class="gl-link__wrapper"
    :href="url"
    :target="target"
  ><slot /></a>
</template>

<script>
export default {
  name: 'GlLink',
  props: {
    url: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
}
</script>
<style>
.gl-link__wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Content-Link);
  text-decoration: none;
}
.gl-link__wrapper:visited {
  color: var(--Content-Link);
}
</style>
