<template>
  <div class="report-info-block__wrapper">
    <div class="report-info-block__label">
      {{ label }}
    </div>

    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      :color="'#bec3d8'"
      :height="20"
      :radius="'2'"
      type="rect"
    />
    <div
      v-else-if="$slots.value"
      class="report-info-block__slot-value"
    >
      <slot name="value" />
    </div>
    <div v-else>
      <div
        class="report-info-block__value-wrapper"
        :class="{
          'report-info-block__value-wrapper--ellipsis': ellipsisValue,
        }"
        @click="$emit('clickedValue')"
      >
        <div
          class="report-info-block__value"
          :class="{
            'report-info-block__value--ellipsis': ellipsisValue,
          }"
        >
          {{ value || '–' }}
        </div>

        <div
          v-if="rate"
          v-popover:tooltip.top="
            rateTime ? `${priceMessage(rateTime, rateTimestamp)}` : null
          "
          class="report-info-block__price"
        >
          {{ formatByPrice(rate) }}
        </div>
        <div v-if="withCopy && value">
          <gl-menu-item
            v-popover:tooltip.top="'Copy'"
            icon="copy-blue"
            :icon-height="20"
            :icon-width="20"
            @click="copy(value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VueSkeletonLoader from 'skeleton-loader-vue'
import GlMenuItem from '@/components/gl-menu-item'
// Utils
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { toClipBoard } from '@/utils/helpers'

export default {
  name: 'InfoBlock',
  components: {
    VueSkeletonLoader,
    GlMenuItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    withCopy: {
      type: Boolean,
      default: false,
    },
    ellipsisValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rate: {
      type: [String, Number],
      default: '',
    },
    rateTime: {
      type: [String],
      default: '',
    },
    rateTimestamp: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    priceMessage,
    toClipBoard,
    formatByPrice,
    async copy(value) {
      await this.toClipBoard(value)
    },
  },
}
</script>

<style>
.report-info-block__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.report-info-block__value-wrapper,
.report-info-block__slot-value {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 4px;
}
.report-info-block__label {
  color: var(--Accent-Dark-Grey);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.report-info-block__value {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.report-info-block__price {
  color: var(--Accent-Dark-Grey);
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 2px;
}

.report-info-block__value-wrapper--ellipsis {
  align-items: flex-start;
  flex-wrap: nowrap;
}
.report-info-block__value--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
