<template>
  <div class="report-address-info__general">
    <InfoBlock
      label="Blockchain"
      :loading="loading"
    >
      <template #value>
        <GlBlockchainIcon :blockchain-icon="info.coinKey" />
        <span
          class="report-info-block__value uppercase"
        >{{ info.coinKey?.toUpperCase() }}
        </span>
      </template>
    </InfoBlock>
    <InfoBlock
      label="Author"
      value="Global Ledger"
    />
    <InfoBlock
      label="Report Generated"
      :loading="loading"
      :value="
        info.createdAt ? formatDate(info.createdAt, 'dd.MM.yyyy HH:mm') : null
      "
    />
  </div>
</template>

<script>
// Components
import InfoBlock from '@/pages/report/components/info/InfoBlock'
import GlBlockchainIcon from '@/components/gl-blockchain-icon'
// utils
import { formatDate } from '@/utils/format-date'
// vuex
import { mapState } from 'vuex'

export default {
  name: 'GeneralInfo',
  components: {
    InfoBlock,
    GlBlockchainIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  methods: { formatDate },
}
</script>
<style>
.report-address-info__general {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
@media (max-width: 767px) {
  .report-address-info__general {
    grid-template-columns: repeat(2, 1fr);
  }
  .report-address-info__general > *:nth-child(2) {
    order: 3;
  }
}
</style>
