var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-info-block__wrapper"},[_c('div',{staticClass:"report-info-block__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.loading)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":20,"radius":'2',"type":"rect"}}):(_vm.$slots.value)?_c('div',{staticClass:"report-info-block__slot-value"},[_vm._t("value")],2):_c('div',[_c('div',{staticClass:"report-info-block__value-wrapper",class:{
        'report-info-block__value-wrapper--ellipsis': _vm.ellipsisValue,
      },on:{"click":function($event){return _vm.$emit('clickedValue')}}},[_c('div',{staticClass:"report-info-block__value",class:{
          'report-info-block__value--ellipsis': _vm.ellipsisValue,
        }},[_vm._v(" "+_vm._s(_vm.value || '–')+" ")]),(_vm.rate)?_c('div',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:(
          _vm.rateTime ? `${_vm.priceMessage(_vm.rateTime, _vm.rateTimestamp)}` : null
        ),expression:"\n          rateTime ? `${priceMessage(rateTime, rateTimestamp)}` : null\n        ",arg:"tooltip",modifiers:{"top":true}}],staticClass:"report-info-block__price"},[_vm._v(" "+_vm._s(_vm.formatByPrice(_vm.rate))+" ")]):_vm._e(),(_vm.withCopy && _vm.value)?_c('div',[_c('gl-menu-item',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('Copy'),expression:"'Copy'",arg:"tooltip",modifiers:{"top":true}}],attrs:{"icon":"copy-blue","icon-height":20,"icon-width":20},on:{"click":function($event){return _vm.copy(_vm.value)}}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }