import { mapActions } from 'vuex'; // Import mapActions from vuex
export const AlertsMixin = {
    methods: {
        ...mapActions('alerts', ['getAlertsByAddress', 'getAlertsByIds']),

        async loadAlerts(address, alertsData = null) {
            try {
                let alerts = [];
                if (alertsData) {
                    alerts = alertsData;
                } else {
                    const { alerts: fetchedAlerts } = await this.getAlertsByAddress(address);
                    alerts = fetchedAlerts;
                }

                const isTriggeredAlerts = alerts.filter(al => al.count > 0);
                const alertsIds = isTriggeredAlerts.map(al => al.alertId);

                const { items } = alertsIds.length ? await this.getAlertsByIds(alertsIds) : [];
                const resultArray = items.map(el => {
                    const alert = isTriggeredAlerts.find(al => al.alertId === el.id);
                    return { ...el, ...alert };
                });

                return resultArray || [];
            } catch (error) {
                this.$toasted.global.error('Error loading alerts');
                return [];
            }
        },
    },
};
