import appConfig from '@/utils/appConfig'

const lowPoint = appConfig.VUE_APP_RISK_LOW_THRESHOLD
const mediumPoint = appConfig.VUE_APP_RISK_MEDIUM_THRESHOLD
const mediumDefaultPoint = appConfig.VUE_APP_RISK_MEDIUM_DEFAULT
const highPoint = appConfig.VUE_APP_RISK_HIGH_THRESHOLD

const lowRiskLabel = 'low'
const mediumRiskLabel = 'medium'
const highRiskLabel = 'high'
const unknownRiskLabel = 'unknown'
const pendingLabel = 'pending'

export function calculateRiskLevel(score) {
  if (typeof score === 'string' && score.trim() !== '') {
    score = Number(score)
  }

  if (typeof score !== 'number' || isNaN(score)) {
    return null
  }

  if (score == -2) return pendingLabel

  if (score >= 0 && score <= lowPoint) {
    return lowRiskLabel
  } else if (score > lowPoint && score <= mediumPoint) {
    return mediumRiskLabel
  } else if (score > mediumPoint && score <= highPoint) {
    return highRiskLabel
  } else {
    return unknownRiskLabel
  }
}

export function calculateScoreByRiskLevel(risk) {
  if (!risk) return -1
  if (risk == pendingLabel) return -2

  if (risk === lowRiskLabel) {
    return lowPoint
  } else if (risk === mediumRiskLabel) {
    return mediumDefaultPoint
  } else if (risk === highRiskLabel) {
    return highPoint
  } else {
    return -1
  }
}
