var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-profile-info__wrapper"},[(_vm.loading)?_c('VueSkeletonLoader',{staticClass:"mb-4",attrs:{"animation":"wave","color":'#bec3d8',"height":40,"radius":'2',"type":"rect"}}):_vm._e(),(_vm.showOwnerBlock || _vm.showOwnerLabels)?_c('div',{staticClass:"report-profile-info__owner-block"},[(_vm.showOwnerBlock)?_c('ProfileOwner',{attrs:{"owner-data":_vm.profileInfo.ownerData,"title":"Account holder"}}):_vm._e(),(_vm.showOwnerLabels)?_c('ProfileLabels',{attrs:{"owner-data":_vm.profileInfo.ownerData}}):_vm._e()],1):_vm._e(),(_vm.showClusterBlock || _vm.showClusterLabels)?_c('div',{staticClass:"report-profile-info__owner-block"},[(_vm.showClusterBlock)?_c('ProfileOwner',{attrs:{"owner-data":_vm.profileInfo.clusterData,"title":"Entity"}}):_vm._e(),(_vm.showClusterLabels)?_c('ProfileLabels',{attrs:{"owner-data":_vm.profileInfo.clusterData}}):_vm._e()],1):_vm._e(),(
      !_vm.loading &&
        !_vm.showClusterBlock &&
        !_vm.showOwnerBlock &&
        !_vm.showClusterLabels &&
        !_vm.showOwnerLabels
    )?_c('div',[_vm._v(" Not identified ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }