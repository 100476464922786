<template>
  <img
    :height="`${height}${units}`"
    :src="resSrc"
    :width="`${width}${units}`"
  >
</template>

<script>
export default {
  props: {
    srcVal: {
      type: String,
      default: '',
      require: true
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    units: {
      type: String,
      default: 'px',
    }
  },
  computed: {
    resSrc() {
      return `data:image/png;base64, ${this.srcVal}`
    }
  }
}
</script>
