export const scrollToSelector = (
  patentSelector,
  targetSelector,
  offsetTop = 0,
) => {
  const main = document.querySelector(patentSelector)

  main.scrollTo({
    top: document.querySelector(targetSelector)?.offsetTop - offsetTop,
    left: 0,
    behavior: 'smooth',
  })
}

export const scrollToViewSelector = (aim, block = 'start') => {
  const main = document.querySelector(aim)

  main?.scrollIntoView({
    behavior: 'smooth',
    block: block,
  })
}
