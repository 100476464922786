import { toComaSeparate } from '@/utils/formatNumber'

export const txsAddPlus = (data, threshold = 1000001) => {
  const letter = threshold >= 1000000 ? 'M' : 'K'
  
  if (data >= threshold) {
    const numStr = data.toString()
    let displayValue = ''

    if (data >= 100000000) {
      displayValue = numStr.slice(0, 3)
    } else if (data >= 10000000) {
      displayValue = numStr.slice(0, 2)
    } else if (data >= 1000000) {
      displayValue = numStr.charAt(0)
    } else if (data >= 100000) {
      displayValue = numStr.slice(0, 3)
    } else if (data >= 10000) {
      displayValue = numStr.slice(0, 2)
    } else {
      displayValue = numStr.charAt(0)
    }

    return data >= threshold ? `${+displayValue}${letter}+` : toComaSeparate(data)
  }
  
  return data
}