<template>
  <div class="alerts-popup-bell__wrapper">
    <GlPopup v-model="isAlertOpen">
      <template #action>
        <div
          class="alerts-popup-bell__action"
          @click="isAlertOpen = !isAlertOpen"
        >
          <div
            v-show="flagsData.length && !isAlertOpen"
            class="alerts-popup-bell__action-counter"
          >
            {{ flagsData.length }}
          </div>
          <gl-icon
            :height="24"
            name="alerts2"
            :width="21"
          />
        </div>
      </template>
      <template #content>
        <div class="alerts-popup-bell__content-wrapper">
          <div class="alerts-popup-bell__content-head">
            Scheme
          </div>
          <div class="alerts-popup-bell__content-body">
            <GlFlagsWrap
              :flags-data="flagsData"
              hide-score
              :loading="loading"
              :max-count="flagsData.length"
            />
          </div>
        </div>
      </template>
    </GlPopup>
  </div>
</template>

<script>
// Components
import GlPopup from '@/components/gl-popup'
import GlFlagsWrap from '@/components/gl-flags-wrap'
import GlIcon from '@/components/gl-icon'

export default {
  name: 'AlertsPopupBell',
  components: {
    GlPopup,
    GlFlagsWrap,
    GlIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    flagsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAlertOpen: false,
    }
  },
}
</script>
<style>
.alerts-popup-bell__wrapper {
  cursor: pointer;
}
.alerts-popup-bell__wrapper .gl-popup-content {
  top: 52px;
  right: 0;
  max-width: 320px;
}
.alerts-popup-bell__content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
}
.alerts-popup-bell__action {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 8px;
  border: 1px solid var(--Accent-Coton-Grey);
  background: var(--white);
  width: 44px;
  height: 44px;
}
.alerts-popup-bell__action-counter {
  position: absolute;
  top: 2px;
  right: 2px;
  background: var(--Accent-Scarled-Red);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  border-radius: 13px;
  padding: 2px 3px 2px 4px;
  min-width: 13px;
}
.alerts-popup-bell__content-head {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.alerts-popup-bell__content-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 320px;
}
</style>
