import router from '@/router'

export const goToEntity = id => {
  if (id) {
    const routeData = router.resolve({
      name: `view-entity-explorer`,
      params: { entityId: id },
    })
	
    window.open(routeData.href, '_blank')
  }
}

export const goMailto = link => {
  if (!link) return
  window.location.href = `mailto:${link}`
}
