export const priorityMap = {
    'high': '#FF2A00',
    'medium': '#F8C65A',
    'low': '#70AC3F',
    '100': '#FF2A00',
    '50': '#F8C65A',
    '0': '#70AC3F',
    'def': '#5A78EA',
}

export const priorityMapLabels = {
    'high': 'High',
    'medium': 'Medium',
    'low': 'Low',
    '100': 'High',
    '50': 'Medium',
    '0': 'Low',
    'def': '--',
}

export const getPriorityColor = (name) => {
    if (!name.toString() || !priorityMap[name]) return priorityMap['def']
    return priorityMap[name]
};
