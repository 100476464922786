
<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    :loading="loading"
    overflowing-form
    submit-title="Select"
    title="Choose a Token"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <vSelect
      v-model="token"
      class="mb-4"
      :clearable="false"
      label="symbol"
      :options="tokensList"
    >
      <template v-slot:selected-option="option">
        <GlImg
          v-if="option.icon"
          class="mr-1"
          :src-val="option.icon"
        />
        {{ option.symbol }}
      </template>
      <template v-slot:option="option">
        <GlImg
          v-if="option.icon"
          class="mr-1"
          :src-val="option.icon"
        />
        {{ option.symbol }}
        <div
          v-if="option.isMonitored"
          class="grey-text-6-e ml-2 fs-12"
        >
          (This token is monitored)
        </div>
      </template>
    </vSelect>
  </gl-modal>
</template>

<script>
// Components
import vSelect from "vue-select";
import GlModal from '@/components/gl-modal';
import GlImg from "@/components/gl-img.vue";
// Vuex
import { mapState } from "vuex";
// Utils
import { tokensSorting } from "@/utils/cytoskape-ui-rules";

export default {
  components: {
    GlImg,
    GlModal,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    tokens: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      token: {},
      loading: false,
      tokensList: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData'])
  },
  mounted() {
    this.token = this.tokens[0]
    if (this.tokens) {
      this.tokensList = this.tokens
    }
  },
  methods: {
    tokensSorting,
    onSubmit() {
      this.$emit('select', this.token)
      this.$emit('close')
    },
  },
}
</script>
