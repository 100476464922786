<template>
  <div
    v-popover:tooltip.top="
      `This wallet address is being monitored, you are able to receive fast and detailed reports as well as alerts in case of new incoming transactions, new counterparties, or AML/CTF risks identified. This report was generated by using the last ${
        coinData.family === 'eth' ? '1000' : '1000'
      } incoming transactions of the requested address.`
    "
    class="monitored-button-wrapper"
  >
    <gl-icon
      height="16"
      :hover="false"
      name="done"
      success
      width="16"
    />
    Monitored
  </div>
</template>

<script>
// components
import GlIcon from '@/components/gl-icon'
// vuex
import { mapState } from 'vuex'

export default {
  name: 'MonitoredButton',
  components: { GlIcon },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
}
</script>

<style>
.monitored-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  font-weight: 600;
  line-height: 24px;
  color: var(--Accent-Green);
  border-radius: var(--Corner-radius-S);
  background: rgba(112, 172, 63, 0.1);
}
@media (max-width: 1200px) {
  .monitored-button-wrapper {
    gap: 4px;
    padding: 8px 16px;
    line-height: 16px;
  }
}
</style>
