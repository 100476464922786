<template>
  <div>
    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      class="mb-4"
      :color="'#bec3d8'"
      :height="40"
      :radius="'2'"
      type="rect"
    />
    <div
      v-else
      class="report-tx-actions__wrapper"
    >
      <div class="report-tx-actions__left">
        <div class="report-tx-actions__title">
          {{ title }}
        </div>
      </div>

      <div class="report-tx-actions__right" />
    </div>
  </div>
</template>

<script>
// Components
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'TopTxActions',
  components: {
    VueSkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return 'Transaction Risk Report'
    },
  },
}
</script>
<style>
.report-tx-actions__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 24px;
}
.report-tx-actions__title {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}
.report-tx-actions__left,
.report-tx-actions__right {
  display: flex;
  align-items: center;
  gap: 24px;
}

@media (max-width: 1200px) {
  .report-tx-actions__title {
    font-size: 30px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .report-tx-actions__title {
    font-size: 24px;
    line-height: 32px;
  }
  .report-tx-actions__left {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
