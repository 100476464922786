<template>
  <div>
    <div
      v-if="tooltip"
      v-popover:tooltip.top="`${tooltip}`"
      class="gl-priority-chip"
      :class="{
        'gl-priority-chip-rounded-s': rounded === 's',
        'gl-priority-chip-rounded-m': rounded === 'm',
        'gl-priority-chip-rounded-l': rounded === 'l',
        'gl-priority-chip-without-label': !label,
        uppercase: uppercaseMode,
      }"
      :style="{ 'background-color': getPriorityColor(priorityLevel) }"
    >
      <slot name="first" />
      <slot name="second" />
      <div
        v-if="label"
        class="flex-1 fs-12"
      >
        {{ label }}
      </div>
    </div>
    <div
      v-else
      class="gl-priority-chip"
      :class="{
        'gl-priority-chip-rounded-s': rounded === 's',
        'gl-priority-chip-rounded-m': rounded === 'm',
        'gl-priority-chip-rounded-l': rounded === 'l',
        'gl-priority-chip-without-label': !label,
        uppercase: uppercaseMode,
      }"
      :style="{ 'background-color': getPriorityColor(priorityLevel) }"
    >
      <slot name="first" />
      <slot name="second" />
      <div
        v-if="label"
        class="flex-1 fs-12"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { getPriorityColor } from '@/utils/priority'

export default {
  props: {
    label: {
      type: [String, Number],
      default: '',
    },
    tooltip: {
      type: String,
      require: true,
      default: '',
    },
    priorityLevel: {
      type: [String, Number],
      require: true,
      default: 'def',
    },
    rounded: {
      type: String,
      default: 's',
    },
    uppercaseMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPriorityColor,
  },
}
</script>

<style>
.gl-priority-chip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white);
  white-space: nowrap;
  line-height: 1.3;
  text-align: center;
}
.gl-priority-chip-rounded-s {
  padding: 4px 19px;
  border-radius: 3px;
}
.gl-priority-chip-rounded-m {
  padding: 4px 8px;
  border-radius: 8px;
}
.gl-priority-chip-rounded-l {
  gap: 6px;
  padding: 4px;
  border-radius: 14px;
}
.gl-priority-chip-without-label {
  gap: 4px;
  padding: 1px;
  padding-left: 4px;
}
</style>
