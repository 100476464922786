<template>
  <div class="page-wrapper">
    <div
      v-if="title"
      class="flex space-between align-center gap-3 m-wrap page-wrapper__header page-wrapper__header-is-empty"
    >
      <h1
        v-if="showTitle"
        class="page-title"
      >
        {{ title }}
      </h1>
      <slot name="actions" />
    </div>
    <slot />
  </div>
</template>

<script>
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
export default {
  mixins: [deviceWidthMixin],
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    hideTitleOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showTitle() {
      return !(this.isMobile && this.hideTitleOnMobile)
    },
  },
}
</script>

<style>
.page-wrapper__header {
  margin-bottom: 13px;
}
.page-wrapper__header-is-empty:empty {
  display: none;
}
.entity-page__wrapper .gl-search-box__independent {
  padding-left: 16px;
  letter-spacing: 0.5px;
}
</style>