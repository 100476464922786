<template>
  <div class="add-to-monitoring-button__wrapper">
    <GlButton
      :icon-only="iconOnly"
      :loading="addToMonitoringLoading"
      pale-light
      title="Add to monitoring"
      @click="onClickAddToMonitoring"
    >
      <template #beforeTitle>
        <gl-icon
          class="mr-2"
          height="17"
          link
          name="add-icon"
          width="17"
        />
      </template>
    </GlButton>
  </div>
</template>

<script>
// Components
import GlButton from '@/components/gl-button'
import GlIcon from '@/components/gl-icon'
// Utils
import { isExceptionStatus } from '@/utils/accesses'

// Vuex
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddToMonitoringButton',
  components: {
    GlButton,
    GlIcon,
  },
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addToMonitoringLoading: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  methods: {
    ...mapActions({
      addToMonitoring: 'monitoring/addToMonitoring',
    }),
    isExceptionStatus,
    async onClickAddToMonitoring() {
      if (!this.address) return
      this.addToMonitoringLoading = true
      this.addToMonitoring({
        address: this.address,
        checkLastTen: false,
        blockchain: this.coinData.label,
      })
        .then(({ success, data }) => {
          if (success) {
            this.$toasted.global.success({ message: `Added to monitoring` })
          } else if (!isExceptionStatus(data.status)) {
            this.$toasted.global.error({ message: `${data.message}` })
          }
        })
        .catch(({ response: { data } }) => {
          this.$toasted.global.error({ message: `${data.data.message}` })
        })
        .finally(() => {
          this.addToMonitoringLoading = false
          setTimeout(() => {
            this.$emit('on-added-to-monitoring', this.address)
          }, 1000)
        })
    },
  },
}
</script>
