<template>
  <div class="gl-avatar">
    <div
      v-if="restCount > 0"
      class="gl-avatar__rest"
    >
      +{{ restCount }}
    </div>

    <div
      v-for="(item, i) in displayAvatarList"
      :key="i"
      class="gl-avatar__icon-wrapper"
    >
      <GlImg
        v-if="item.icon"
        class="gl-avatar__icon"
        :src-val="item.icon"
      />
      <div
        v-else
        class="gl-avatar__default"
      >
        {{ getFirstLetter(item[labelInListKey] ?? '-') }}
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlImg from '@/components/gl-img.vue'
// Utils
import { getFirstLetter } from '@/utils/text-formatter'

export default {
  components: {
    GlImg,
  },
  props: {
    displayAvatarsCount: {
      type: Number,
      default: 3,
    },
    avatarList: {
      type: [Array],
      default: () => [],
    },
    labelInListKey: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      isSelected: false,
    }
  },
  computed: {
    displayAvatarList() {
      return this.avatarList.slice(0, this.displayAvatarsCount).reverse()
    },
    restCount() {
      return this.avatarList.length - this.displayAvatarList.length
    },
  },
  methods: {
    getFirstLetter,
  },
}
</script>

<style>
.gl-avatar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.gl-avatar__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  outline: 1px solid var(--white);
  background-color: var(--white);
  z-index: 1;
}

.gl-avatar__icon {
  border-radius: 50%;
}

.gl-avatar__icon-wrapper:not(:last-child) {
  margin-left: -8px;
}

.gl-avatar__default {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--dark-grey);
  color: var(--space-cadet);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
}

.gl-avatar__rest {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding-left: 16px;
  padding-right: 8px;
  background: var(--cotton-grey-f-5);
  margin-left: -8px;
  border-radius: 0 16px 16px 0;
}
</style>
