<template>
  <div class="report-profile-info__wrapper">
    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      class="mb-4"
      :color="'#bec3d8'"
      :height="40"
      :radius="'2'"
      type="rect"
    />

    <div
      v-if="showOwnerBlock || showOwnerLabels"
      class="report-profile-info__owner-block"
    >
      <ProfileOwner
        v-if="showOwnerBlock"
        :owner-data="profileInfo.ownerData"
        title="Account holder"
      />
      <ProfileLabels
        v-if="showOwnerLabels"
        :owner-data="profileInfo.ownerData"
      />
    </div>

    <div
      v-if="showClusterBlock || showClusterLabels"
      class="report-profile-info__owner-block"
    >
      <ProfileOwner
        v-if="showClusterBlock"
        :owner-data="profileInfo.clusterData"
        title="Entity"
      />
      <ProfileLabels
        v-if="showClusterLabels"
        :owner-data="profileInfo.clusterData"
      />
    </div>

    <div
      v-if="
        !loading &&
          !showClusterBlock &&
          !showOwnerBlock &&
          !showClusterLabels &&
          !showOwnerLabels
      "
    >
      Not identified
    </div>
  </div>
</template>

<script>
// Components
import VueSkeletonLoader from 'skeleton-loader-vue'
import ProfileOwner from '@/pages/report/components/profile-info/ProfileOwner'
import ProfileLabels from '@/pages/report/components/profile-info/ProfileLabels'

export default {
  name: 'ProfileInfo',
  components: { VueSkeletonLoader, ProfileLabels, ProfileOwner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    profileInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showClusterBlock() {
      const cluster = this.profileInfo.clusterData || {}
      return (cluster.owner || cluster.description) && !this.loading
    },
    showOwnerBlock() {
      const ownerData = this.profileInfo.ownerData || {}
      return (ownerData.owner || ownerData.description) && !this.loading
    },
    showClusterLabels() {
      const clusterType =
        this.profileInfo.clusterData?.type ||
        this.profileInfo.clusterData?.meta?.length

      return clusterType && !this.loading
    },
    showOwnerLabels() {
      const ownerType =
        this.profileInfo.ownerData?.type ||
        this.profileInfo.ownerData?.meta?.length
      return ownerType && !this.loading
    },
  },
  methods: {},
}
</script>
<style>
.report-profile-info__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.report-profile-info__owner-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
