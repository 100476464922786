var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tooltip)?_c('div',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:(`${_vm.tooltip}`),expression:"`${tooltip}`",arg:"tooltip",modifiers:{"top":true}}],staticClass:"gl-priority-chip",class:{
      'gl-priority-chip-rounded-s': _vm.rounded === 's',
      'gl-priority-chip-rounded-m': _vm.rounded === 'm',
      'gl-priority-chip-rounded-l': _vm.rounded === 'l',
      'gl-priority-chip-without-label': !_vm.label,
      uppercase: _vm.uppercaseMode,
    },style:({ 'background-color': _vm.getPriorityColor(_vm.priorityLevel) })},[_vm._t("first"),_vm._t("second"),(_vm.label)?_c('div',{staticClass:"flex-1 fs-12"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],2):_c('div',{staticClass:"gl-priority-chip",class:{
      'gl-priority-chip-rounded-s': _vm.rounded === 's',
      'gl-priority-chip-rounded-m': _vm.rounded === 'm',
      'gl-priority-chip-rounded-l': _vm.rounded === 'l',
      'gl-priority-chip-without-label': !_vm.label,
      uppercase: _vm.uppercaseMode,
    },style:({ 'background-color': _vm.getPriorityColor(_vm.priorityLevel) })},[_vm._t("first"),_vm._t("second"),(_vm.label)?_c('div',{staticClass:"flex-1 fs-12"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }