import userState from "@/store/modules/user/state"
import { restrictNumberAfterComma, toComaSeparate } from "@/utils/formatNumber";
import { formatDate } from "@/utils/format-date";

const defaultFiat = userState.fiatList[0]

export const formatByPrice = (price, fiat) => {
	if (!price || !Number(price)) return ''
	let currencySymbol = findFiatByKey(defaultFiat.key).label
	if (userState.userData.preferredCurrency && findFiatByKey(userState.userData.preferredCurrency)) {
		currencySymbol = findFiatByKey(userState.userData.preferredCurrency).label
	} 
	return `${Math.abs(price) < 0.01 ? '< 0.01' : toComaSeparate(restrictNumberAfterComma(price, 2))} ${fiat || currencySymbol}` 
}

export const priceMessage = (time, timestamp) => {
	const messages = {
		current: `Current Price: This value reflects\nthe most recent market price of\nthe token in fiat currency.${timestamp ? '\n(' + formatDate(timestamp, 'dd.MM.yyyy HH:mm') + ')' : ''}`,
		historical: `Historical Price: This value represents\nthe fiat equivalent of the token at the\ntime the transaction occurred.${timestamp ? '\n(' + formatDate(timestamp, 'dd.MM.yyyy HH:mm') + ')' : ''}`,
		calculatedAt: `Prices are calculated at: ${timestamp ? '(' + formatDate(timestamp, 'dd.MM.yyyy HH:mm') + ')' : ''}`,
	};
	return messages[time] || '';
}

export const formatTotalByPrice = (getAmount, priceArray, fiat) => {
	if (!getAmount || !priceArray) return ''
	let currencySymbol = findFiatByKey(defaultFiat.key).label
	if (userState.userData.preferredCurrency) currencySymbol = findFiatByKey(userState.userData.preferredCurrency).label

    const sum = priceArray.reduce((total, el) => {
		return total + (getAmount(el.amount) * el.rate)
	}, 0); 

	return `${fiat || currencySymbol} ${restrictNumberAfterComma(sum, 8)}` 
}

export const findFiatByKey = (key) => {
	if (!key) return ''
	return userState.fiatList.find(e => e.key === key)
}
