<template>
  <div class="gl-row__wrapper">
    <div
      v-if="$slots.value"
      class="gl-row__value"
      :class="{
        'gl-row__value--ellipsis': ellipsis,
      }"
    >
      <slot name="value" />
    </div>
    <div
      v-else
      class="gl-row__value"
      :class="{
        'gl-row__value--ellipsis': ellipsis,
      }"
    >
      {{ value || '–' }}
    </div>
    <gl-icon
      v-if="iconName"
      :height="iconHeight"
      :link="isIconLink"
      :name="iconName"
      :width="iconWidth"
    />
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'

export default {
  name: 'GlRowWrapper',
  components: {
    GlIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    iconHeight: {
      type: Number,
      default: 20,
    },
    iconWidth: {
      type: Number,
      default: 20,
    },
    isIconLink: {
      type: Boolean,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.gl-row__wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}
.gl-row__wrapper .gl-icon {
  min-width: unset;
}
.gl-row__value {
  flex: 1;
}
.gl-row__value--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
