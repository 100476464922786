<template>
  <div class="report-address-info__wrapper">
    <SectionBlock class="mb-4">
      <template #title>
        Address Information
      </template>
      <template #body>
        <div class="report-address-info__body-wrapper">
          <div class="report-address-info__body-top">
            <InfoBlock
              ellipsis-value
              label="Address"
              :loading="loading"
              :value="info.address"
              with-copy
            />
            <GeneralInfo
              :info="info"
              :loading="loading"
            />
          </div>
          <div
            class="report-address-info__body-middle"
            :class="{
              'report-address-info__body-middle-split': hasAdditionalInfo,
            }"
          >
            <InfoBlock
              v-if="!essential || (essential && parseCurrencyString(info.balance))"
              :label="hasAdditionalInfo ? 'Balance' : 'Token Balance'"
              :loading="loading"
              :rate="info.price"
              rate-time="current"
              :rate-timestamp="info.priceTimestamp"
              :value="info.balance"
            />
            <InfoBlock
              v-if="!essential || ((essential && parseCurrencyString(info.totalTransactions)) || parseCurrencyString(info.totalTransactions))"
              label="Total Transactions"
              :loading="loading"
              :value="info.totalTransactions"
            />
            <InfoBlock
              v-if="!essential || ((essential && parseCurrencyString(info.transactionsSent)) || parseCurrencyString(info.totalTransactions))"
              label="Transactions Sent"
              :loading="loading"
              :value="info.transactionsSent"
            />
            <InfoBlock
              v-if="!essential || ((essential && parseCurrencyString(info.transactionsReceived) || parseCurrencyString(info.totalTransactions)))"
              label="Transactions Received"
              :loading="loading"
              :value="info.transactionsReceived"
            />

            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Received"
              :loading="loading"
              :rate="info.totalReceivedPrice"
              rate-time="current"
              :rate-timestamp="info.totalReceivedPriceTimestamp"
              :value="info.totalReceived"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="First Seen"
              :loading="loading"
              :value="info.firstSeen"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Sent"
              :loading="loading"
              :rate="info.totalSentPrice"
              rate-time="current"
              :rate-timestamp="info.totalSentPriceTimestamp"
              :value="info.totalSent"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Last Seen"
              :loading="loading"
              :value="info.lastSeen"
            />
          </div>
        </div>
      </template>
    </SectionBlock>
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import InfoBlock from '@/pages/report/components/info/InfoBlock'
import GeneralInfo from '@/pages/report/components/info/GeneralInfo'
import { parseCurrencyString } from '@/utils/report-data-formatter'

export default {
  name: 'AddressInfoWrapper',
  components: {
    SectionBlock,
    InfoBlock,
    GeneralInfo,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasAdditionalInfo: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    essential: {
      type: Boolean,
      default: false,
    }
  },
  methods: { parseCurrencyString },
}
</script>
<style>
.report-address-info__body-wrapper {
  margin-bottom: 24px;
}
.report-address-info__body-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--Color-Unknown-tag);
}
.report-address-info__body-middle {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 30px;
}

@media (min-width: 1200px) {
  .report-address-info__body-middle-split > *:nth-child(4) {
    grid-column: 2;
    grid-row: 1;
  }
  .report-address-info__body-middle-split > *:nth-child(5) {
    grid-column: 3;
    grid-row: 1;
  }
  .report-address-info__body-middle-split > *:nth-child(6) {
    grid-column: 4;
    grid-row: 1;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .report-address-info__body-middle-split > *:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
}

@media (max-width: 1200px) {
  .report-address-info__body-middle {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .report-address-info__body-middle {
    grid-template-columns: 1fr;
  }
  .report-address-info__body-middle-split > *:nth-child(6) {
    grid-column: 1;
    grid-row: 7;
  }
  .report-address-info__body-middle-split > *:nth-child(7) {
    grid-column: 1;
    grid-row: 5;
  }
}
</style>
